import React from 'react'
import Helmet from 'react-helmet'
import { Layout, Heading, Row, Column, Image, Section, Text } from 'components'
import Blog from 'images/blogs/blog-17.png'
import 'components/css/stories.css'

const SITE_META = [
  {
    name: 'description',
    content: 'Mabilis, Madali, Mura',
  },
  {
    name: 'keywords',
    content:
      'digipay, fintech, e-payments, bills payment, ' +
      'mobile money, e-load, e-pins, microinsurance',
  },
  {
    property: 'og:url',
    content: `https://${process.env.GATSBY_REACT_APP_HOSTNAME}/stories/DigipayAgentsIn5LoveLanguages`,
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:title',
    content: 'Digipay Agents in 5 Love Languages',
  },
  {
    property: 'og:description',
    content:
      'Paano mo ipinapakita ang iyong pagmamahal sa iyong mga suki, ka-Digipay?',
  },
  {
    property: 'og:image',
    content: Blog,
  },
]

const ReasonsWhy = () => (
  <Layout>
    <Helmet title="Digipay Agents in 5 Love Languages" meta={SITE_META}>
      <html lang="en" />
    </Helmet>

    <Row>
      <Column size="df:[100%] lg:[col-6]" py="sp4" px="sp3" m="auto">
        <Image width="100%" mb="sp3" src={Blog} />
        <Heading
          className="blog__title"
          mb="sp4"
          fontWeight="bold"
          textAlign="center"
        >
          DIGIPAY AGENTS IN 5 LOVE LANGUAGES
        </Heading>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            WORDS OF APIR-MATION 🙏🏻
          </Heading>
          <Text>
            Para sa’yo, mahalaga ang bawat salita na binibitawan mo sa iyong mga
            suki. Tuwing pupunta sila sa iyong tindahan, mahilig kang bumati
            nang may ngiti sa labi. Tiwala sila na maayos kang kausap dahil
            hindi ka din pabago-bago ng convenience fees. At kung successful man
            o failed ang transaction, transparent ka sa kanila dahil
            ipinapaliwanag mo agad kung nagkaroon man ng aberya. Apir tayo
            diyan, ka-Digipay!
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            PHY-SEE-CAL TOUCH 👀
          </Heading>
          <Text>
            Hindi naman ibig sabihin nito ay kailangan mong yakapin ang iyong
            mga suki. Ang gusto nila, lagi kang available tuwing kailangan
            nilang magbayad ng bills o magpadala. Kung bukas ang iyong tindahan
            mula Lunes hanggang Sabado ng 8AM - 6PM, aaasahan nila ‘yan sa lahat
            ng pagkakataon. Kung wala ka naman sa ganitong panahon, mas mabuti
            na ipaalam mo na agad ito nang mas maaga. Basta makita lang nila ang
            presensya mo, it makes a big difference!
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            G! WHEN GIVING GIFTS 🎁
          </Heading>
          <Text>
            Kung mahal mo ang iyong negosyo, hindi ka mawawalan ng loyal
            customers. Sila ‘yung totoong naniniwala sa serbisyo na ibinibigay
            mo. Kaya paminsan-minsan, hindi masamang bigyan sila ng munting
            regalo para naman maramdaman nila na pinapahalagahan mo sila bilang
            mga suki. Isa itong magandang paraan para lalo silang magtiwala sa
            business mo. Maganda itong gawin kapag Christmas season because it’s
            the season of giving!
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            MAGLAAN NG ORAS? NO RUSH! ⌚
          </Heading>
          <Text>
            Mas maganda kung itinuturing mo ring kaibigan ang iyong mga suki.
            Iwasan mong iparamdam sa kanila na mahirap kang i-approach tuwing
            magbabayad sila sa’yo. Kahit na busy ka at madaming customers, treat
            them fairly para hindi sila lumayo at hindi na bumalik. Masakit
            ‘yun, ka-Digipay! Mas masarap sa feeling kapag malapit sa’yo ang
            iyong mga suki, ‘di ba?
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            TANGING BISYO? MAGBIGAY SERBISYO! 😍
          </Heading>
          <Text>
            A successful business is all about great service. Agree ka ba dito?
            Araw-araw, dapat 100% effort ang binibigay mo sa negosyo. I-maximize
            mo ang services ng Digipay para mas mapagaan ang buhay ng iyong mga
            suki. Bukod sa tubig at kuryente, pwedeng pwede silang magbayad ng
            cable, internet, loans, at marami pang iba! Sa huli, mararamdaman
            nila kung gaano kadami ang natipid nilang oras at pamasahe dahil may
            Digipay Agent silang kapitbahay!
          </Text>
          <Text>Hanggang sa muli, ka-Digipay!</Text>
        </Section>
      </Column>
    </Row>
  </Layout>
)

export default ReasonsWhy
